<template>
  <div class="list">
    <van-empty description="暂无项目记录" v-show="emptyList" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=" —— 我也是有底线的 —— "
        @load="onLoad"
        :offset="10"
        style="margin-bottom: 39px"
      >
        <van-cell-group v-for="(item, index) in listData" :key="index">
          <van-card>
            <template #title>
              <div class="list_title" @click="getDetail(item.p_id)">
                <van-tag
                  plain
                  style="font-size: 0.8rem; margin-right: 5px"
                  type="primary"
                  >{{ item.created_at }}</van-tag
                >
                {{ item.p_name }}
                <div style="float: right">
                  <van-tag
                    :type="item.report_type_style"
                    style="margin-right: 3px"
                    >{{ item.report_type }}</van-tag
                  >
                  <van-tag
                    plain
                    style="margin-right: 3px"
                    :type="item.status_style"
                    >{{ item.status }}</van-tag
                  >
                </div>
              </div>
            </template>
            <template #price>
              <div
                @click="getEvaluateObj(item.p_id)"
                style="
                  float: left;
                  font-weight: bold;
                  font-size: 1rem;
                  margin-top: 5px;
                "
              >
                ¥{{ item.total_price == null ? "暂无价格" : item.total_price }}
              </div>
            </template>
            <template #footer>
              <div style="padding-bottom: 20px; margin-top: -10px">
                <div>
                  <van-tag
                    type="primary"
                    style="float: right; margin-left: 5px"
                    plain
                    @click="get_status(item.p_id)"
                    >项目进度</van-tag
                  >
                </div>
                <div
                  v-if="
                    item.status == '待签字' &&
                    (uId == item.evaluator_fir || uId == item.evaluator_sec)
                  "
                >
                  <van-tag
                    v-if="
                      (item.evaluator_fir == uId &&
                        item.evaluator_fir_time == null) ||
                      (item.evaluator_sec == uId &&
                        item.evaluator_sec_time == null)
                    "
                    type="danger"
                    style="float: right; margin-left: 5px"
                    @click="signProject(item.p_id)"
                    >待签字</van-tag
                  >
                  <van-tag
                    v-if="
                      (item.evaluator_fir == uId &&
                        item.evaluator_fir_time !== null) ||
                      (item.evaluator_sec == uId &&
                        item.evaluator_sec_time !== null)
                    "
                    type="success"
                    style="float: right; margin-left: 5px"
                    @click="repeatTips()"
                    >已签字</van-tag
                  >
                </div>
                <div
                  v-if="item.status == '待确认价格'"
                  style="float: right; margin-left: 5px"
                >
                  <van-tag type="primary" plain @click="priceConfirm(item.p_id)"
                    >确认价格</van-tag
                  >
                </div>
                <div
                  v-if="item.status == '待审核'"
                  style="float: right; margin-left: 5px"
                >
                  <van-tag
                    type="success"
                    plain
                    @click="checkEvaluate(item.p_id)"
                    style="margin-left: 5px"
                    >审核通过</van-tag
                  >
                  <van-tag
                    type="warning"
                    plain
                    @click="backToEvaluate(item.p_id)"
                    style="margin-left: 5px"
                    >退回估价</van-tag
                  >
                </div>
              </div>
            </template>
          </van-card>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
    <van-popup
      close-icon="close"
      style="width: 100%"
      v-model="show_status"
      closeable
    >
      <van-steps style="margin-top: 10%" direction="vertical" :active="0">
        <van-step v-for="(item, index) in statusList" :key="index">
          <h3>{{ item.action_name }} 操作人:{{ item.user }}</h3>
          <p>{{ item.status_time }}</p>
        </van-step>
      </van-steps>
    </van-popup>
    <van-popup
      close-icon="close"
      style="width: 100%"
      v-model="show_detail"
      closeable
    >
      <van-cell-group style="padding-top: 40px">
        <van-cell title="项目名称" :value="pName" />
        <van-cell title="报告编号" :value="reportNo" />
        <van-cell title="联系人">
          <div v-html="contactor"></div>
        </van-cell>
        <van-cell title="评估机构" :value="organs" />
        <van-cell title="目标机构" :value="targetOrgans" />
        <van-cell title="业务员" :value="smId" />
        <van-cell title="估价员" :value="priceChecker" />
        <van-cell title="审核员" :value="checkerFir" />
        <van-cell title="签字估价师" :value="evaluatorFir" />
        <van-cell title="签字估价师" :value="evaluatorSec" />
      </van-cell-group>
    </van-popup>
    <van-popup
      close-icon="close"
      style="width: 100%"
      v-model="show_object"
      closeable
    >
      <div
        style="padding-top: 30px"
        v-for="(item, index) in evaluationObj"
        :key="index"
      >
        <van-cell-group :title="item.obj">
          <van-cell title="面积" :value="item.area" />
          <van-cell title="单价(元)" :value="item.unit_price" />
          <van-cell title="总价(元)" :value="item.total_price" />
        </van-cell-group>
      </div>
    </van-popup>
    <van-popup
      close-icon="close"
      style="width: 90%"
      v-model="show_back_remark"
      closeable
    >
      <van-form @submit="submitBack" style="margin-top:30px;">
        <van-field
          required
          v-model="backRemark"
          rows="3"
          autosize
          label="退回原因"
          type="textarea"
          placeholder="请输入退回原因"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "List",
  inject: ["reload"],
  data() {
    return {
      emptyList: false,
      listData: [],
      loading: false,
      finished: false,
      refreshing: false,
      list: 0,
      page: 1,
      projectStatus: this.$route.query.status ? this.$route.query.status : "",
      uId: "",
      show_status: false,
      statusList: [],
      show_detail: false,
      show_object: false,
      pName: "",
      reportNo: "",
      contactor: "",
      organs: "",
      targetOrgans: "",
      smId: "",
      priceChecker: "",
      checkerFir: "",
      evaluatorFir: "",
      evaluatorSec: "",
      evaluationObj: [],
      backRemark: "",
      show_back_remark: false,
    };
  },
  created() {
    this.uId = sessionStorage.getItem("uid");
  },
  methods: {
    //>退回估价
    backToEvaluate(p_id) {
      this.show_back_remark = true;
      sessionStorage.setItem('backPid',p_id);
    },
    //>退回估价提交
    submitBack(values) {
      var that = this;
      this.$axios.post("/submit_back", qs.stringify({ 'p_id': sessionStorage.getItem('backPid'), 'remark':that.backRemark})).then(
        function (res) {
          if (res.data.code == 500) {
            that.$toast.fail(res.data.msg);
          } else {
            that.$toast.success(res.data.msg);
            setTimeout(that.reload(),300);
          }
        },
        function (err) {}
      );
    },
    //>项目审核
    checkEvaluate(p_id) {
      var that = this;
      this.$axios
        .post("/check_confirm", qs.stringify({ 'p_id': p_id }))
        .then(function (res) {
          if (res.data.code == 500) {
            that.$toast.fail(res.data.msg);
          } else {
            that.$toast.success(res.data.msg);
            setTimeout(that.reload(), 300);
          }
        });
    },
    //>获取估价对象
    getEvaluateObj(p_id) {
      var that = this;
      this.$axios.get("/evaluate_obj?p_id=" + p_id).then(
        function (res) {
          if (res.data.code == 500) {
            that.$toast.fail(res.data.msg);
          } else {
            that.show_object = true;
            that.evaluationObj = res.data;
          }
        },
        function (err) {}
      );
    },
    //>项目签字
    signProject(p_id) {
      var that = this;
      this.$axios.post("/sign_project", qs.stringify({ p_id: p_id })).then(
        function (res) {
          if (res.data.code == 500) {
            that.$toast.fail(res.data.msg);
          } else {
            that.$toast.success(res.data.msg);
            setTimeout(that.reload(), 300);
          }
        },
        function (err) {}
      );
    },
    //>重复提示
    repeatTips() {
      this.$toast.fail("无需重复签字!");
    },
    //>确认价格
    priceConfirm(p_id) {
      var that = this;
      this.$axios.post("/priceconfirm", qs.stringify({ p_id: p_id })).then(
        function (res) {
          if (res.data.code == 500) {
            that.$toast.fail(res.data.msg);
          } else {
            that.$toast.success(res.data.msg);
            setTimeout(that.reload(), 300);
          }
        },
        function (err) {}
      );
    },
    //>获取项目进度
    get_status(p_id) {
      var that = this;
      this.$axios.get("/project_status?p_id=" + p_id).then(
        function (res) {
          if (res.data.code == 500) {
            that.$toast.fail(res.data.msg);
            that.$router.push(res.data.url);
          } else {
            that.statusList = JSON.parse(res.data);
            that.show_status = true;
          }
        },
        function (err) {}
      );
    },
    //>获取详情
    getDetail(p_id) {
      var that = this;
      this.$axios.get("/project_detail?p_id=" + p_id).then(
        function (res) {
          if (res.data.code == 500) {
            that.$toast.fail(res.data.msg);
            return false;
          }
          that.show_detail = true;
          that.pName = res.data.p_name;
          that.reportNo = res.data.report_no;
          that.contactor = res.data.contactor;
          that.organs = res.data.organs;
          that.targetOrgans = res.data.target_organs;
          that.smId = res.data.sm_id;
          that.priceChecker = res.data.price_checker;
          that.checkerFir = res.data.checker_fir;
          that.evaluatorFir = res.data.evaluator_fir;
          that.evaluatorSec = res.data.evaluator_sec;
        },
        function (err) {}
      );
    },
    //>获取列表
    getList() {
      var that = this;
      this.$axios
        .get(
          "/list?limit=10&page=" + that.page + "&status=" + that.projectStatus
        )
        .then(
          function (res) {
            if (res.data.code == 500) {
              that.emptyList = true;
              that.loading = false;
              that.finished = true;
              if (res.data.msg) {
                that.$toast.fail(res.data.msg);
              }
              if (res.data.url) {
                that.$router.push(res.data.url);
              }
            } else {
              if (that.refreshing) {
                that.list = [];
                that.refreshing = false;
              }
              that.page++;
              if (
                JSON.stringify(that.listData) == JSON.stringify(res.data.data)
              ) {
                that.listData = res.data.data;
                that.finished = true;
                that.loading = false;
                that.$toast.success(res.data.msg);
              } else if (res.data.code == 500) {
                that.$toast.fail(res.data.msg);
                that.loading = false;
                that.finished = true;
              } else {
                that.listData = that.listData.concat(res.data.data);
              }
              that.emptyList = that.listData.length === 0 ? true : false;
              that.list = res.data.num;
              that.loading = false;
              if (that.listData.length >= that.list) {
                that.finished = true;
              }
            }
          },
          function (err) {
            console.log(err);
          }
        );
    },
    //>列表加载
    onLoad() {
      setTimeout(() => {
        this.getList();
        this.loading = true;
      }, 500);
    },
    onRefresh() {
      var that = this;
      setTimeout(() => {
        //>重新初始化这些属性
        that.loading = true;
        that.list = [];
        that.page = 1;
        that.loading = false;
        that.finished = false;
        that.emptyList = false;
        //>请求信息
        that.getList();
      }, 500);
    },
  },
};
</script>
<style scoped>
.history {
  margin-bottom: 10%;
}
.van-cell {
  text-align: left;
}
.van-card__content {
  text-align: left;
}
.list_title {
  /* line-height:1rem; */
  font-size: 0.9rem;
  word-wrap: break-word;
  word-break: break-all;
  font-weight: bold;
  /* text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  width:100%;
  height:3rem; */
}
/* .van-card__bottom .van-card__price {
  text-align: right;
} */
.van-card__price {
  width: 100%;
}
/* .van-list__finished-text {
  margin-bottom: 50px !important;
} */
.van-step__icon--active {
  font-size: 1.2rem;
}
.van-cell-group__title {
  text-align: left;
  font-weight: bolder;
}
</style>
<template>
  <van-nav-bar fixed placeholder :title="title" left-arrow @click-left="onClickLeft" @click-right="onClickRight">
    <template style="font-size:15px;" #right>
      <van-icon name="plus" />
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  data(){
    return {
      title:'新瑞图评估作业系统'
    }
  },
  // created() {
  //   this.getTitle();
  // },
  methods: {
    // getTitle() {
    //   var that = this;
    //   this.$axios.get("http://xrt.com/api/title").then(
    //     function (response) {
    //       that.title = response.data;
    //     },
    //     function (err) {
    //       console.log(err);
    //     }
    //   );
    // },
    onClickRight(){
       this.$router.push('/project');
    },
    onClickLeft() {
      if (window.history.length > 1) {
        history.go(-1);
      } else {
        history.go("/");
        
      }
    },
  },
};
</script>
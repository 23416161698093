import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import './plugins/vant.js'
import 'vant/lib/index.css';
import 'ant-design-vue/dist/antd.css'
import '../public/static/iconfont/iconfont.css';
import './assets/iconfont/iconfont.js';
import { Select } from 'ant-design-vue'

Vue.use(Select);

axios.defaults.baseURL = 'https://www.321pg.com/weadmin/';
axios.defaults.withCredentials = true;

Vue.config.productionTip = false
Vue.prototype.$axios= axios

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div>
    <div class="login_logo">
      <img src="../assets/logo.jpeg" />
    </div>
    <div
      style="font-weight:700;font-size:30px;text-align:center;margin-bottom:35px;margin-top:45%;"
    >新瑞图评估作业系统</div>
    <div class="login_logo_border_bottom"></div>
    <div class="login_wechat_login">
      <a href="https://www.321pg.com/weadmin/login">
        <svg class="icon" aria-hidden="true" style="font-size:50px;color:forestgreen;">
          <use xlink:href="#icon-weixin-copy" />
        </svg>
        <div class="login_wechat_login_font">登录</div>
      </a>
    </div>
    <div class="web-footer" style="bottom:0;padding-bottom:0;">
      <p>
        Copyright © 2020-2021
        <a href="http://www.321pg.com/mobile/index">新瑞图快评系统</a>
      </p>
      <p>
        <a
          href="http://www.beian.miit.gov.cn"
          target="_blank"
          rel="nofollow"
          style="color:black;text-decoration:none;"
        >闽ICP备13011148号-3</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryMsg: this.$route.query.msg == '' || this.$route.query.msg == undefined ? '' : this.$route.query.msg,
    };
  },
  created() {
    if(this.queryMsg == ''){
      return true;
    }else{
      this.tips(this.queryMsg);
    }
  },
  methods:{
    tips(queryMsg){
      this.$toast.fail(queryMsg);
    }
  }
};
</script>
<style scoped>
svg {
  position: relative !important;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.login_logo {
  margin: 0 auto;
  text-align-last: left;
}
.login_logo > img {
  width: 50%;
  margin-left: 15px;
}
.login_logo_border_bottom {
  border-bottom: 1px solid black;
  width: 80%;
  margin: 0 auto 30px auto;
}
.login_wechat_login {
  border-radius: 50%;
  margin: 0 auto;
  height: 200px;
  width: 200px;
  color: white;
  text-align: center;
}
.login_wechat_login_font {
  padding-top: 5px;
  font-size: 10px;
  font-weight: 700;
}
a {
  color: #111;
  text-decoration: none;
}
.web-footer {
  position: absolute;
  width: 100%;
  /* height: 40px; */
  bottom: 70px;
  left: 0;
  text-align: center;
  color: #999;
  z-index: 2;
  padding-bottom: 10px;
  font-size: 13px;
}
</style>
<template>
  <div id="check_form">
    <div v-if="realname !== ''" style="margin-bottom:1rem;">
      当前微信绑定用户:
      <br />
      <span style="font-weight:bold;font-size:1.2rem;">{{realname}}</span>
      <br />确认无误后请在下方输入账号
    </div>
    <div v-if="realname == ''" style="margin-bottom:1rem;font-weight:bold;">当前微信首次登陆,输入系统账号密码登陆!</div>
    <van-form @submit="onSubmit">
      <van-field
        required
        v-model="username"
        name="username"
        label="账号"
        placeholder="输入账号(手机号)"
        :rules="[{ required: true, message: '请正确输入账号' }]"
      />
      <van-field
        required
        v-model="password"
        name="password"
        type="password"
        label="密码"
        placeholder="请输入密码"
      />
      <van-notice-bar
        v-if="roles !== ''"
        wrapable
        :scrollable="false"
        text="当前账号拥有如下身份,请选择一种身份登录:〖必选〗"
      />
      <van-radio-group
        v-if="roles !== ''"
        v-model="radio"
        direction="horizontal"
        style="justify-content:center;margin:1rem auto;"
      >
        <van-radio
          v-for="(item,key) in roles"
          :name="item.id"
          :key="key"
          @click="setRadio(item.id)"
        >{{item.name}}</van-radio>
      </van-radio-group>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          <span style="font-weight:bold;font-size:1rem;">登 录</span>
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import qs from "qs";
export default {
  data() {
    return {
      username: "",
      password: "",
      realname: this.$route.query.real == undefined || this.$route.query.real == "" ? '' : this.$route.query.real,
      roles: this.$route.query.roles == undefined || this.$route.query.roles == "" ? '' : JSON.parse(this.$route.query.roles),
      radio: "",
    };
  },
  mounted() {
  },
  methods: {
    setRadio(value) {
      if (this.radio == "") {
        this.radio = value;
      }
    },
    onSubmit(values) {
      var that = this;
      values.role = this.radio;
      if(this.realname == "" || this.realname == undefined){
        values.firstLogin = true;
      }
      this.$axios
        .post("/check_mobile", qs.stringify(values), {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then(
          function (res) {
            if (res.data.code == 500) {
              that.$toast.fail(res.data.msg);
              if (res.data.url) {
                that.$router.push(res.data.url);
              }
            } else {
              that.$toast.success(res.data.msg);
              sessionStorage.setItem("user_role", that.radio);
              sessionStorage.setItem("uid",res.data.u_id);
              that.$router.push(res.data.url);
            }
          },
          function (err) {}
        );
    },
  },
};
</script>
<style scoped>
#check_form {
  padding-top: 45%;
  height: 100% !important;
}
</style>
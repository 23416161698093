<template>
    <van-form @submit="submitContactor">
  <van-field
    v-model="name"
    name="name"
    label="姓名"
    placeholder="姓名"
    required
    :rules="[{ required: true, message: '请填写联系人姓名' }]"
  />
  <van-field
    v-model="mobile"
    name="mobile"
    label="手机号"
    type="tel"
    required
    placeholder="输入手机号"
    :rules="[{ required: true, message: '请填写联系人手机号' }]"
  />
  <van-field required name="sex" label="性别">
        <template #input>
          <van-radio-group v-model="sex" direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
  <div style="margin: 16px;">
    <van-button round block type="info" native-type="submit">
      提交
    </van-button>
  </div>
</van-form>
</template>
<script>
import querystring from "querystring";
export default {
    data(){
        return{
            name:'',
            mobile:'',
            sex:'1',
            uid:sessionStorage.getItem('uid'),
        }
    },
    created(){
    },
    props: {
      addContactorBack: {
        type: Function,
        default: null
      }
    },
    methods:{
        submitContactor(values){
            values.uid = this.uid;
            var that = this;
            this.$axios.post('/add_contactor',querystring.stringify(values)).then(function(res){
                that.addContactorBack(res.data);
            },function(err){})
        }
    },
}
</script>
<style scoped>

</style>
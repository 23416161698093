<template>
  <van-tabbar v-model="active">
    <van-tabbar-item v-for="(item,index) in tabbarList" :name="item.name" :key="index" :to="item.path" :icon="item.ico">{{item.title}}</van-tabbar-item>
    <!-- <van-tabbar-item href="/history" icon="todo-list-o">历史</van-tabbar-item>
    <van-tabbar-item href="/feed_back" icon="comment-o">反馈</van-tabbar-item>
    <van-tabbar-item href="/me" icon="friends-o">我</van-tabbar-item> -->
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      active: 'Home',
      tabbarList: [
        {
          path: "/",
          title: "工作台",
          ico: "home-o",
          name:'Home',
        },
        {
          path: "/list",
          title: "列表",
          ico: "todo-list-o",
          name:"List",
        },
        {
          path: "/project",
          title: "建项",
          ico: "comment-o",
          name:"ProjectForm",
        },
        {
          path: "/me",
          title: "我",
          ico: "friends-o",
          name:"Me",
        }
      ],
    };
  },
  //监听路由变化
  watch: {
    $route(to) {
      this.activeTab(to.name);
    },
  },

  mounted(){
    this.active = sessionStorage.getItem('active');
    if(this.active == '' || this.active == null){
      this.active = this.$route.name;
    }
  },

  methods: {
    activeTab(name) {
      // var index = this.tabbarList.map((item) => item.path).indexOf(path);
      // var that = this;
      // console.log(that);
      this.active = name;
      sessionStorage.setItem('active',this.active);
    },
  },
};
</script>
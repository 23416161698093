<template>
  <div class="form" style="margin-top: 5px">
    <van-form @submit="onSubmit">
      <van-field
        readonly
        required
        clickable
        name="pType"
        :value="projectType"
        label="项目类型"
        placeholder="选择项目类型"
        @click="showPType = true"
      />
      <van-popup v-model="showPType" position="bottom">
        <van-picker
          show-toolbar
          :columns="pTypeList"
          @confirm="onConfirmPType"
          @cancel="showPType = false"
        />
      </van-popup>
      <van-field
        required
        readonly
        clickable
        name="reportType"
        :value="reportType"
        label="报告类型"
        placeholder="选择报告类型"
        @click="showReportType = true"
      />
      <van-popup v-model="showReportType" position="bottom">
        <van-picker
          show-toolbar
          :columns="reportTypeList"
          @confirm="onConfirmReportType"
          @cancel="showReportType = false"
        />
      </van-popup>
      <van-field
        required
        v-model="pName"
        name="pName"
        label="项目名称"
        placeholder="请输入项目名称"
      />
      <van-field
        readonly
        clickable
        required
        name="area"
        :value="areaValue"
        label="地区选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
        :error-message="countyError"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="onConfirmArea"
          @cancel="showArea = false"
        />
      </van-popup>
      <van-field
        required
        name="contactor"
        label="联系人"
        readonly
        :value="contactor"
        :error-message="contactorError"
      >
        <template #input>
          <a-select
            show-search
            placeholder="输入联系人号码查询"
            :value="contactValue"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="searchNull"
            @search="handleSearch"
            @change="handleChange"
          >
            <a-select-option v-for="d in data" :key="d.value">{{
              d.text
            }}</a-select-option>
          </a-select>
        </template>
        <template #button>
          <div style="text-align: center" @click="addContactor()">
            <svg
              class="icon"
              aria-hidden="true"
              style="width: 20px; height: 20px"
            >
              <use xlink:href="#icon-follow" />
            </svg>
          </div>
        </template>
      </van-field>
      <van-popup v-model="showAddContactor" style="width: 90%;position: absolute;">
        <add-contact :addContactorBack="addContactorBack"></add-contact>
      </van-popup>
      <van-field
        required
        readonly
        clickable
        name="etName"
        :value="etName"
        label="估价目的"
        placeholder="选择估价目的"
        @click="showTarget = true"
      />
      <van-popup v-model="showTarget" position="bottom">
        <van-picker
          show-toolbar
          :columns="targetList"
          @confirm="onConfirmEvaluateTarget"
          @cancel="showTarget = false"
        />
      </van-popup>
      <van-field required name="secHand" label="是否二手">
        <template #input>
          <van-radio-group v-model="secHand" direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <!-- <van-field
        required
        readonly
        clickable
        name="targetOrgans"
        :value="toName"
        label="目标机构"
        placeholder="选择目标机构"
        @click="showTargetOrgans = true"
      />
      <van-popup v-model="showTargetOrgans" position="bottom">
        <van-picker
          show-toolbar
          :columns="targetOrgansList"
          @confirm="onConfirmTargetOrgans"
          @cancel="showTargetOrgans = false"
        />
      </van-popup> -->
      <van-field
        required
        name="targetOrgans"
        label="目标机构"
        readonly
        :value="toName"
        :error-message="contactorError"
      >
        <template #input>
          <a-select
            show-search
            placeholder="输入机构名称,如:农行或农业银行"
            :value="toName"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="toSearchNull"
            @search="handleSearchTo"
            @change="handleChangeTo"
          >
            <a-select-option v-for="d in targetOrgansList" :key="d.value">{{
              d.text
            }}</a-select-option>
          </a-select>
        </template>
      </van-field>
      <van-field
        required
        readonly
        clickable
        name="organs"
        :value="organName"
        label="评估机构"
        placeholder="选择评估机构"
        @click="showOrgans = true"
      />
      <van-popup v-model="showOrgans" position="bottom">
        <van-picker
          show-toolbar
          :columns="organsList"
          @confirm="onConfirmOrgans"
          @cancel="showOrgans = false"
        />
      </van-popup>
      <van-field
        v-model="message"
        rows="3"
        autosize
        label="备注"
        type="textarea"
        placeholder="请输入项目备注"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
// @ is an alias to /src
import AreaList from "../assets/Area.json";
import querystring from "querystring";
import qs from "qs";
import AddContactor from "../components/AddContactor";
let timeout;
let currentValue;
let toTimeout;
let toCurrentValue;
export default {
  name: "Form",
  components: {
    "add-contact": AddContactor,
  },
  data() {
    return {
      showAddContactor: false,
      ptId: "",
      projectType: "",
      showPType: false,
      pTypeList: [],
      showReportType: false,
      reportType: "",
      reportTypeList: [],
      reportTypeId: "",
      pName: "",
      areaValue: "",
      showArea: false,
      areaList: AreaList,
      countyCode: "",
      cityCode: "",
      provinceCode: "",
      countyError: "",
      data: [],
      contactor: "",
      contactValue: "",
      contactorError: "",
      etId: "",
      etName: "",
      showTarget: false,
      targetList: [],
      secHand: "2",
      toId: "",
      targetOrgansList: [],
      toName: "",
      showTargetOrgans: false,
      showOrgans: false,
      organName: "",
      organsList: [],
      organId: "",
      searchNull: "输入手机号,未显示姓名请右侧添加!",
      toSearchNull: "输入目标机构名查询,如:农业银行.",
    };
  },
  created() {
    this.loadProjectFormOptions();
  },
  mounted() {},
  methods: {
    //>项目表单选项加载
    loadProjectFormOptions() {
      var that = this;
      this.$axios.get("/form_base").then(
        function (res) {
          that.organsList = res.data.organs;
          that.targetOrgansList = res.data.target_organs;
          that.targetList = res.data.evaluate_target;
          that.pTypeList = res.data.project_category;
          that.reportTypeList = res.data.report_type;
        },
        function (err) {}
      );
    },
    //>项目类型确认
    onConfirmPType(values) {
      this.ptId = values.value;
      this.projectType = values.text;
      this.showPType = false;
    },
    //>报告类型确认
    onConfirmReportType(values) {
      this.reportType = values.text;
      this.reportTypeId = values.value;
      this.showReportType = false;
    },
    //>省市区确认
    onConfirmArea(values) {
      this.areaValue = values.map((item) => item.name).join("-");
      this.countyCode = values[2].code;
      this.cityCode = values[1].code;
      this.provinceCode = values[0].code;
      this.showArea = false;
    },
    //>联系人相关
    handleSearch(value) {
      this.data = [];
      this.fetch(value, (data) => (this.data = data));
      // fetch(value, (data) => (this.data = data));
    },
    handleChange(value) {
      this.contactor = value;
      this.contactValue = value;
      this.fetch(value, (data) => (this.data = data));
    },
    //>联系人搜索
    fetch(value, callback) {
      var that = this;
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      currentValue = value;

      function fake() {
        const str = querystring.encode({
          q: value,
        });
        that.$axios.get("/contactor_search?" + str).then(
          function (response) {
            if (currentValue === value) {
              const data = [];
              var result = response.data;

              if (result.code == 500) {
                that.searchNull = "查无联系人,点击右边图标新增!";
              } else {
                result.forEach((r) => {
                  data.push({
                    value: r.id,
                    text: r.text,
                  });
                });
              }
              if (currentValue == "") {
                that.data = [];
                that.searchNull = "输入手机号,未显示姓名请右侧添加!";
              } else {
                that.data = data;
              }
            }
          },
          function (err) {}
        );
      }
      timeout = setTimeout(fake, 300);
    },
    //>目标机构相关
    handleSearchTo(value) {
      this.targetOrgansList = [];
      this.fetchTo(value, (data) => (this.targetOrgansList = data));
      // fetch(value, (data) => (this.data = data));
    },
    handleChangeTo(value) {
      this.toName = value;
      this.toId = value;
      // this.contactValue = value;
      this.fetchTo(value, (data) => (this.targetOrgansList = data));
    },
    //>目标机构查找
    fetchTo(value, callback) {
      var that = this;
      if (toTimeout) {
        clearTimeout(toTimeout);
        toTimeout = null;
      }
      toCurrentValue = value;

      function fakeTo() {
        const str = querystring.encode({
          q: value,
        });
        that.$axios.get("/target_organs_search?" + str).then(
          function (response) {
            if (toCurrentValue === value) {
              const data = [];
              var result = response.data;

              if (result.code == 500) {
                that.toSearchNull = "查无此机构,联系管理员!";
              } else {
                result.forEach((r) => {
                  data.push({
                    value: r.id,
                    text: r.text,
                  });
                });
              }
              if (toCurrentValue == "") {
                that.targetOrgansList = [];
                that.toSearchNull = "查无此机构,联系管理员!";
              } else {
                that.targetOrgansList = data;
              }
            }
          },
          function (err) {}
        );
      }
      toTimeout = setTimeout(fakeTo, 300);
    },
    //>新增联系人弹窗
    addContactor() {
      this.showAddContactor = true;
    },
    //>新增联系人回调
    addContactorBack(values) {
      if (values.code == 200) {
        this.showAddContactor = false;
        this.$toast.success("联系人已更新,请重新选择!");
      } else {
        this.$toast.fail(values.msg);
      }
    },
    //>估价目的确认
    onConfirmEvaluateTarget(values) {
      this.etId = values.value;
      this.etName = values.text;
      this.showTarget = false;
    },
    //>目标机构确认
    // onConfirmTargetOrgans(values) {
    //   console.log(values);
    // },
    //>评估机构确认
    onConfirmOrgans(values) {
      this.organName = values.text;
      this.organId = values.value;
      this.showOrgans = false;
    },
    //>表单提交
    onSubmit(values) {
      values.countyCode = this.countyCode;
      values.provinceCode = this.provinceCode;
      values.cityCode = this.cityCode;
      values.organId = this.organId;
      values.etId = this.etId;
      values.reportTypeId = this.reportTypeId;
      values.ptId = this.ptId;
      var that = this;
      this.$axios
        .post("/project_create", qs.stringify(values), {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then(
          function (res) {
            if (res.data.code == 500) {
              that.$toast.fail(res.data.msg);
            } else {
              that.$toast.success(res.data.msg);
              that.$router.push("/list");
            }
          },
          function (err) {}
        );
    },
  },
};
</script>
<style scoped>
.ant-select-selection {
  border: 1px solid white !important;
}
.ant-select-selection__rendered {
  margin-left: 0 !important;
}
/* .ant-select-selection__placeholder { */
/* display: block !important; */
/* user-select:none !important; */
/* } */
</style>
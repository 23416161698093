import Vue from 'vue'
import VueRouter from 'vue-router'
import Form from '../components/Form.vue'
import List from '../views/List.vue'
import Index from '../views/WorkIndex.vue'
import Me from '../views/Me.vue'
import Login from '../views/Login.vue'
import CheckMobile from '../components/CheckMobileLogin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/project',
    name: 'ProjectForm',
    component: Form,
    meta:{
       show_public:true,
       title:'新瑞图评估作业系统'
    }
  },
  {
    path:'/list',
    name:'List',
    component:List,
    meta:{
      show_public:true,
      title:'新瑞图评估作业系统'
   }
  },
  {
    path:'/',
    name:'Home',
    component:Index,
    meta:{
      show_public:true,
      title:'新瑞图评估作业系统'
   }
  },
  {
    path:'/me',
    name:'Me',
    component:Me,
    meta:{
      show_public:true,
      title:'新瑞图评估作业系统'
   }
  },
  {
    path:'/login',
    name:'Login',
    component:Login,
    meta:{
      show_public:false,
      title:'新瑞图评估作业系统'
   },
  },
  {
    path:'/check_mobile_login',
    name:'CheckMobile',
    component:CheckMobile,
    meta:{
      show_public:false,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

export default router

<template>
  <div id="app">
    <app-header v-if="$route.meta.show_public"></app-header>
    <router-view v-if="isRouterAlive"></router-view>
    <app-footer v-if="$route.meta.show_public"></app-footer>
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
export default {
  name: 'app',
  components: {
    'app-header':Header,
    'app-footer':Footer,
  },
  provide(){
    return{
      reload:this.reload,
    }
  },
  data(){
    return{
      isRouterAlive:true,
    }
  },
  methods:{
     reload(){
       this.isRouterAlive = false;
       var that = this;
       this.$nextTick(function(){
         that.isRouterAlive = true;
       })
     }
  }
}
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>

<template>
  <div class="WorkIndex" style="margin-bottom:20%;">
    <!-- <template>
      <van-grid :border="false" :column-num="1">
        <van-grid-item>
          <van-image :src="imgUrl" />
        </van-grid-item>
      </van-grid>
    </template> -->
    <van-grid :column-num="2">
      <van-grid-item icon="pause-circle-o" text="待指派" :badge="postNum" :to="{name:'List',query:{status:'1'}}" />
      <van-grid-item icon="search" text="待估价" :badge="evaluateNum" :to="{name:'List',query:{status:'2'}}" />
      <van-grid-item icon="question-o" text="待审核" :badge="checkNum" :to="{name:'List',query:{status:'3'}}" />
      <van-grid-item icon="gold-coin-o" text="待确认价格" :badge="confirmNum" :to="{name:'List',query:{status:'4'}}" />
      <van-grid-item icon="todo-list-o" text="待打印" :badge="printNum" :to="{name:'List',query:{status:'5'}}" />
      <van-grid-item icon="sign" text="待签字" :badge="signNum" :to="{name:'List',query:{status:'6'}}" />
      <van-grid-item icon="records" text="待归档" :badge="saveNum" :to="{name:'List',query:{status:'7'}}" />
      <van-grid-item icon="completed" text="已完成" :badge="finishNum" :to="{name:'List',query:{status:'8'}}" />
      <van-grid-item icon="stop-circle-o" text="终止/废除" :badge="stopNum" :to="{name:'List',query:{status:'10'}}" />
    </van-grid>
  </div>
</template>

<script>
import Logo from '../assets/logo.jpeg'
export default {
  name: "WorkIndex",
  data() {
    return {
      imgUrl:Logo,
      postNum:0,//>待指派数
      evaluateNum:0,//>待估价数
      checkNum:0,//>待审核数
      confirmNum:0,//>待确认价格数
      printNum:0,//>待打印数
      signNum:0,//>待签字数
      saveNum:0,//>待归档数
      finishNum:0,//>已完成数
      stopNum:0,//>终止数
    };
  },
  created() {
    this.getCountData();
    if((this.$route.query.u_id !== undefined && this.$route.query.u_id !== '' && this.$route.query.u_id > 1 )|| sessionStorage.getItem('uid') < 1){
      sessionStorage.setItem('uid',this.$route.query.u_id);
    }
  },
  methods: {
      getCountData(){
        var that = this;
        this.$axios.get('/count_data').then(function(res){
          if(res.data.code == 500){
            that.$toast.fail(res.data.msg);
            that.$router.push(res.data.url);
          }else{
            that.postNum = res.data.data.wait_post_num;
            that.evaluateNum = res.data.data.wait_evaluation_num;
            that.checkNum = res.data.data.wait_check_num;
            that.confirmNum = res.data.data.wait_confirm_price_num;
            that.printNum = res.data.data.wait_print_num;
            that.signNum = res.data.data.wait_sign_num;
            that.saveNum = res.data.data.wait_save_num;
            that.finishNum = res.data.data.wait_finish_num;
            that.stopNum = res.data.data.stop_num;
          }
        },function(err){})
      }
  },
};
</script>